import { Injectable } from '@angular/core';
import {InMemoryDbService, RequestInfo} from 'angular-in-memory-web-api'


@Injectable({
  providedIn: 'root'
})
export class BackendService implements InMemoryDbService {

  constructor() { }
  
  createDb() {

   let  orders =  [
     {  id:1,  order_details: ['Shake Shack Burger BLT', 'Spiked Milk Shake']},
     {  id:2,  order_details: ['Keto approved Bunless Burger', 'Zero Calorie Shake']}
   ];

   return {orders};

  }
}
