import { Component, OnInit } from '@angular/core';
import { OAuthService } from 'angular-oauth2-oidc';
import { HttpClient } from '@angular/common/http';
import { OrderService } from '../order.service';


@Component({
  selector: 'app-order',
  templateUrl: './order.component.html',
  styleUrls: ['./order.component.css']
})
export class OrderComponent implements OnInit {

  token: string = null;
  userInfoStr: string = 'User not logged in. Please login !';

  orders: any[] = [];

  constructor(private oauthService:OAuthService, private orderService:OrderService) {
  }

  ngOnInit(): void {
    this.userInfo();
  }

  userInfo() {
    //  const claims = this.oauthService.getIdentityClaims();
    console.log(`getting token for order page..`);
    this.token = this.oauthService.getAccessToken();

    console.log(`getting user info..`);
    if (this.token != null)  {
      console.log(`token not null.. getting user info..`);
      this.oauthService.loadUserProfile()
      .then(userInfo => {
        console.log(`got user info:${userInfo}`);
        this.userInfoStr = `Welcome ${userInfo.sub}`;
        console.log(`got subject:${userInfo.sub}`);
        this.orderService.getOrdersByUser(userInfo.sub).subscribe((data: any) => {
          console.log(data);
          this.orders = data.order_details;
        })
      })
      .catch(() => {
        console.error('error calling userinfo endpoint');
      });
    }
    
  }

}
