import { AuthConfig } from 'angular-oauth2-oidc';

export const authConfig: AuthConfig = {

  // Url of the Identity Provider
  issuer: 'https://auth.glogin.org/',

  // URL of the SPA to redirect the user to after login  + '/index.html'
  // trying without index.html
  redirectUri: window.location.origin,

  // The SPA's id. The SPA is registerd with this id at the auth-server
  clientId: 'demo-angular',

  dummyClientSecret: 'juicy-burger',

  // set the scope for the permissions the client should request
  // The first three are defined by OIDC. The 4th is a usecase-specific one
  scope: 'openid offline',

  responseType: 'token',

  oidc: false,

  requestAccessToken: true,

  //logoutUrl: 

  //clearHashAfterLogin:
  
  showDebugInformation: true
}