<!-- Navbar (sit on top) -->
<div class="w3-top">
  <div class="w3-bar w3-white w3-padding w3-card" style="letter-spacing:4px;">
    <a href="#home" class="w3-bar-item w3-button">Gourmet au Catering</a>
    <!-- Right-sided navbar links. Hide them on small screens -->
    <div class="w3-right w3-hide-small">
      <a [routerLink]="'/home'" class="w3-bar-item w3-button">Home</a>      
      <a href="#menu" class="w3-bar-item w3-button">Menu</a>
      <a [routerLink]="'/order'" class="w3-bar-item w3-button">My Orders</a>      
      <a [routerLink]="'/contact'" class="w3-bar-item w3-button">Contact</a>
      <a (click)="login()" *ngIf="!token" class="w3-bar-item w3-button">Login</a>
      <a (click)="logout()" *ngIf="token" class="w3-bar-item w3-button">Logout</a>
    </div>
  </div>
</div>

<div class="w3-content" style="max-width:1100px">    
  <router-outlet></router-outlet>
</div>  

<!-- Footer -->
<footer class="w3-bottom w3-light-grey w3-padding-32">
  <p>Powered by <a href="https://www.w3schools.com/w3css/default.asp" title="W3.CSS" target="_blank" class="w3-hover-text-green">w3.css</a></p>
</footer>

