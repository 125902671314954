<div class="w3-row w3-padding-64">
    <h2>My Orders</h2> 
    
    <p>{{userInfoStr}}</p>

    <table style="width:100%">
        <tr>
          <th>Recent Orders</th>
          <span>(Would you like to order again ?)</span>
        </tr>
        <tr *ngFor="let order of orders" >
          <td>{{ order }}</td>
        </tr>
    </table>
      
</div>