import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';

@Injectable({
  providedIn: 'root'
})
export class OrderService {

  API_URL: string = "/api/";

  users = new Map();

  constructor(private http: HttpClient) { 
    this.users.set('krishna.thotakura@gmail.com', 1);
    this.users.set('srividya.gainedi@gmail.com', 2);
  }

  getAllOrders() {
    return this.http.get(this.API_URL + 'orders');
  }

  getOrdersByUser(userId) {
    let id = this.users.get(userId);
    return this.http.get(`${this.API_URL + 'orders'}/${id}`);
  }
}
