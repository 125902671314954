import { Component } from '@angular/core';
import { Router } from '@angular/router';
import { OAuthService, JwksValidationHandler } from 'angular-oauth2-oidc';
//import { JwksValidationHandler } from 'angular-oauth2-oidc-jwks';
import { authConfig } from './sso.config';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.css']
})
export class AppComponent {
  title = 'angular-demo';

  constructor(private oauthService:OAuthService, private _router: Router) {
    this.configureSingleSignOn();
  }

  configureSingleSignOn() {
    this.oauthService.configure(authConfig);
    this.oauthService.tokenValidationHandler = new JwksValidationHandler();
    this.oauthService.loadDiscoveryDocumentAndTryLogin();
    //this.oauthService.tryLoginCodeFlow();
  }


  login() {
    console.log(`initiating implicit flow..`);
    this.oauthService.initImplicitFlow();
  }

  get token() {
    console.log(`getting token..`);
    let token:any = this.oauthService.getAccessToken();

    return token ? token : null;
  }


  logout() {
    this.oauthService.logOut();
    this._router.navigate(['home']);
  }


}
